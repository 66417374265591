import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { Link } from 'gatsby';

// markup
const TermsOfUse = () => (
  <Layout>
    <SEO title="Our Terms" description="The Maistro terms of service" />
    <div className="container">
      <article className="post text-base">
        <h5>PLEASE READ THIS CAREFULLY BEFORE USING THIS SITE</h5>

        <p>
          www.maistro.com is a site operated by Maistro UK Limited (“We”),
          registered in England and Wales under company number 06211244 and
          registered office Rowan House North, 1 The Professional Quarter,
          Shrewsbury Business Park, Shrewsbury, United Kingdom, SY2 6LG
        </p>

        <h5>TERMS OF WEBSITE USE</h5>

        <p>
          By using our site, you confirm that you accept these terms of use
          (together with the documents referred to in it) and that you agree to
          comply with them.
        </p>

        <p>
          If you do not agree to these terms, you must not use our site.We may
          amend these terms at any time by posting amended terms on our site,
          please check the terms each time you wish to use the site.
        </p>

        <p>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</p>

        <ul>
          <li>
            Please review our&nbsp;
            <Link to="https://maistro.com/privacy-and-cookie-policy/">
              Privacy Policy
            </Link>
            , which sets out the terms on which we process any personal data we
            collect from you, or that you provide to us. By using our site, you
            consent to such processing and you warrant that all data provided by
            you is accurate.
          </li>
          <li>
            Our&nbsp;
            <Link to="https://maistro.com/privacy-and-cookie-policy/">
              Cookie Policy
            </Link>
            &nbsp;sets out information about the cookies on our site.
          </li>
          <li>
            If you buy or sell from our site, separate terms and conditions will
            apply at the point of contract.
          </li>
        </ul>

        <h5>CHANGES TO OUR SITE</h5>

        <p>
          We may update our site from time to time, and may change the content
          at any time. Content on our site may be out of date at any given time,
          and we are under no obligation to update it.
        </p>

        <h5>ACCESSING OUR SITE</h5>

        <p>
          We do not guarantee that our site, or any content on it, will always
          be available, be free from errors or omissions or be uninterrupted.
          Access to our site is permitted on a temporary basis and may be
          suspended, withdrawn, discontinued or content changed without notice.
          You are responsible for ensuring that all persons who access our site
          through your internet connection are aware of these terms of use and
          other applicable terms and conditions, and that they comply with them.
        </p>

        <h5>INTELLECTUAL PROPERTY RIGHTS</h5>

        <p>
          We are the owner or the licensee of all intellectual property rights
          in our site, and in the material published on it. Those works are
          protected by copyright laws and treaties around the world. All such
          rights are reserved.
        </p>

        <p>
          You must not use any part of the content on our site for commercial
          purposes without obtaining a licence to do so from us or our
          licensors.
        </p>

        <p>
          If you print off, copy or download any part of our site in breach of
          these terms of use, your right to use our site will cease immediately
          and you must, at our option, return or destroy any copies of the
          materials you have made.
        </p>

        <h5>NO RELIANCE ON INFORMATION</h5>

        <p>
          Content on our site is for general information only. It is not
          intended to amount to advice on which you should rely. We make no
          representations, warranties or guarantees, whether express or implied,
          that the content on our site is accurate, complete or up-to-date.
        </p>

        <h5>LIMITATION OF OUR LIABILITY</h5>

        <p>
          To the extent permitted by law, the material displayed on our site is
          without any guarantee, conditions, warranties, representations or
          other terms, express or implied.
        </p>

        <p>
          We will not be liable to any user for any loss or damage, whether in
          contract, tort (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or in connection with
          use of or reliance on any content displayed on our site.
        </p>

        <p>
          If you are a business user, please note that in particular, we will
          not be liable for:
        </p>

        <ul>
          <li>loss of profits, sales, business or revenue;</li>
          <li>business interruption;</li>
          <li>loss of anticipated savings;</li>
          <li>loss of business opportunity, goodwill or reputation; or</li>
          <li>any indirect or consequential loss or damage.</li>
        </ul>

        <p>
          We will not be liable for any loss or damage caused by a virus,
          distributed denial-of-service attack, or other technologically harmful
          material that may infect your computer equipment, computer programs,
          data or other proprietary material due to your use of our site or to
          your downloading of any content on it, or on any website linked to it.
        </p>

        <p>
          We assume no responsibility for the content of websites linked on our
          site. Such links should not be interpreted as endorsement by us of
          those linked websites. We will not be liable for any loss or damage
          that may arise from your use of them.
        </p>

        <p>
          Different limitations and exclusions of liability will apply to
          liability arising as a result of the supply of any goods by use to
          you, which will be set out in our Terms and Conditions as notified at
          the point of purchase.
        </p>

        <h5>UPLOADING CONTENT TO OUR SITE</h5>

        <p>
          Any content you upload to our site will be considered non-confidential
          and non-proprietary. You retain all of your ownership rights in your
          content, but you are required to grant us and other users of our site
          who can view your content a limited licence to use, store and copy
          that content and to distribute and make it available to third parties.
        </p>

        <p>
          We also have the right to disclose your identity to any third party
          who is claiming that any content posted or uploaded by you to our site
          constitutes a violation of their intellectual property rights, or of
          their right to privacy.
        </p>

        <p>
          We will not be responsible, or liable to any third party, for the
          content or accuracy of any content posted by you or any other user of
          our site.
        </p>

        <p>
          We have the right to remove any posting you make on our site if, in
          our opinion it breaches these terms or in our discretion we decide it
          is unsuitable.
        </p>

        <p>
          The views expressed by other users on our site do not represent our
          views or values.
        </p>

        <h5>VIRUSES</h5>

        <p>
          We do not guarantee our site will be secure or free from bugs or
          viruses and accept no liability for loss or damage in respect of the
          same.
        </p>

        <p>
          You must not misuse our site by knowingly introducing viruses,
          trojans, worms, logic bombs or other material which is malicious or
          technologically harmful. You must not attempt to gain unauthorised
          access to our site, the server on which our site is stored or any
          server, computer or database connected to our site. You must not
          attack our site via a denial-of-service attack or a distributed
          denial-of service attack.
        </p>

        <p>
          By breaching this provision, you would commit a criminal offence under
          the Computer Misuse Act 1990. We will report any such breach to the
          relevant law enforcement authorities and we will cooperate with those
          authorities by disclosing your identity to them. In the event of such
          a breach, your right to use our site will cease immediately.
        </p>

        <h5>LINKING TO OUR SITE</h5>

        <p>
          You may not link to our site or scrape content without our prior
          written permission. Our site must not be framed on any other site. We
          reserve the right to withdraw linking or scraping permission without
          notice.
        </p>

        <h5>THIRD PARTY LINKS AND RESOURCES IN OUR SITE</h5>

        <p>
          Where our site contains links to other sites and resources provided by
          third parties, these links are provided for your information only.We
          have no control over the contents of those sites or resources and
          accept no responsibility for them.
        </p>

        <h5>APPLICABLE LAW</h5>

        <p>
          The law of England and Wales will apply to any claim arising through
          the use of our site or these terms of use and the courts of England
          and Wales will have exclusive jurisdiction.
        </p>

        <h5>TRADE MARKS</h5>

        <p>‘Maistro’ is a registered trade mark of Maistro UK Limited.</p>

        <h5>VARIATIONS</h5>

        <p>
          We may revise these terms of use at any time by amending this page.
          You are expected to check this page from time to time to take notice
          of any changes we make, as they are binding on you.
        </p>

        <h5>CONTACT US</h5>

        <p>
          To contact us,&nbsp;
          <Link to="/contact/">Contact Us</Link>
        </p>

        <p>Thank you for visiting our site.</p>
      </article>
    </div>
  </Layout>
);

export default TermsOfUse;
